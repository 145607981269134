import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-up/SignUp";
import "./Style.scss";
import Chat from "./pages/chat/Chat";

const App = () => {
  return (
    <div className="app-wrapper">
      <div className="v-layout">
        <main className="v-main">
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default App;
