import * as React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAxios from "axios-hooks";
import { v4 as uuidv4 } from "uuid";
import SendIcon from "@mui/icons-material/Send";

const messages = [{ id: "a", text: "Hi there!", sender: "bot" }];

const scrollToBottom = (div: HTMLElement | null) => {
  setTimeout(() => {
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  }, 10);
};

export type Message = typeof messages[0];

const Chatbox = ({
  sessionId,
  onClose,
}: {
  sessionId: string;
  onClose: () => void;
}) => {
  const [input, setInput] = React.useState("");
  const [{ loading }, makeRequest] = useAxios({}, { manual: true });
  const [{ loading: endingChat }, callEndChat] = useAxios(
    {
      method: "patch",
    },
    { manual: true }
  );
  const [messages, setMessages] = React.useState<Message[]>([]);

  const addMessage = React.useCallback((m: Message) => {
    setMessages((prev) => prev.concat([m]));
  }, []);

  const sendMessage = React.useCallback(() => {
    addMessage({ id: uuidv4(), text: input, sender: "user" });
    setInput("");
    const div = document.getElementById("chat-box");
    scrollToBottom(div);

    makeRequest({
      url: "/chats",
      method: "post",
      data: {
        session_id: sessionId,
        message: input,
      },
    }).then(({ data }) => {
      addMessage({ id: data.chat.id, text: data.chat.message, sender: "bot" });
      scrollToBottom(div);
    });
  }, [sessionId, input]);

  const handleInputChange = React.useCallback((event: any) => {
    setInput(event.target.value);
  }, []);

  const formSubmit = React.useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const endChat = React.useCallback(() => {
    callEndChat({
      url: `/chats/session/${sessionId}/end`,
    }).then(() => {
      onClose();
    });
  }, [onClose, sessionId]);

  return (
    <Box
      sx={{
        height: "90vh",
        width: "80vw",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.200",
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.5)",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          zIndex: 10,
          display: endingChat ? "flex" : "none",
        }}
      >
        <CircularProgress sx={{ mb: 2 }} />
        Closing chat...
      </Box>
      <Box onClick={endChat} sx={{ p: 2, cursor: "pointer" }}>
        <ArrowBackIcon />
      </Box>
      <Box id="chat-box" sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </Box>
      <Box sx={{ pt: 2, backgroundColor: "background.default" }}>
        <form onSubmit={formSubmit} name="chat-input" target="#here">
          {loading && (
            <>
              <CircularProgress size={16} sx={{ mr: 1 }} /> Waiting for
              response...
            </>
          )}
          <Grid container spacing={0}>
            <Grid sx={{ mr: 10 }} item xs={9}>
              <TextField
                size="small"
                fullWidth
                placeholder="Type a message"
                variant="outlined"
                disabled={endingChat}
                value={input}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                disabled={input === ""}
                color="primary"
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={sendMessage}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

const Message = ({ message }: { message: Message }) => {
  const isBot = message.sender === "bot";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: isBot ? "primary.main" : "secondary.main" }}>
          {isBot ? "B" : "U"}
        </Avatar>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot ? "primary.light" : "secondary.light",
            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          }}
        >
          <Typography variant="body1">{message.text}</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Chatbox;
