import { useState } from "react";
import LogoMini from "../../assets/images/LogoMini.svg";
import "./Style.scss";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
interface response {
  response: string | "No Response";
}
interface formDataType {
  name: string;
  email: string;
  password: string;
}
type errorDataType = {
  name: string;
  email: string;
  password: string;
};
const SignUp = () => {
  const initialValue = { name: "", email: "", password: "" };
  const [formData, setFormData] = useState<formDataType>(initialValue);
  const [response, setResponse] = useState<response>();
  const [formErrors, setFormErrors] = useState<errorDataType>();
  const navigate  = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post<response>(`/auth/signup`, {
        ...formData,
      })
      .then((response) => {
        setResponse(response.data);
        setFormData(initialValue);
        navigate("/chat");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="sign-up-wrapper">
      <div className="signup-form-wrapper">
        <div className="logo">
          <img src={LogoMini} className="logo-icon" alt="logo" />
        </div>
        <div className="auth-form">
          <div className="title">Create your account</div>
          <div className="description">
            {" "}
            Please sign up using your work email. This demo is designed for
            organizations that are exploring the potential use of our products.{" "}
          </div>
          <div>
            <form
              onSubmit={onFormSubmit}
              className={formErrors ? "formError form" : "form"}
            >
              <TextField
                id="outlined-basic"
                label="name"
                name="name"
                variant="outlined"
                type="text"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic1"
                label="Work e-mail"
                name="email"
                variant="outlined"
                type="email"
                onChange={handleChange}
              />
              <TextField
                id="outlined-basic2"
                label="Password"
                name="password"
                variant="outlined"
                type="password"
                onChange={handleChange}
              />
              <Button type="submit" variant="contained">
                Sign up
              </Button>
            </form>
          </div>
          <div className="additional-actions">
            {" "}
            Already have an account? &nbsp;
            <Link to="/sign-in">Log in</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
