import { useState, useEffect } from "react";
import LogoMini from "../../assets/images/LogoMini.svg";
import "./Style.scss";
import { TextField } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@/components/Button";

interface response {
  token: string;
}
interface formDataType {
  email: string;
  password: string;
}
type errorDataType = {
  email: string;
  password: string;
};
const SignIn = () => {
  const initialValue = { email: "", password: "" };
  const [formData, setFormData] = useState<formDataType>(initialValue);
  const [response, setResponse] = useState<response>();
  const [formErrors, setFormErrors] = useState<errorDataType>();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/chat");
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validation = (values: formDataType) => {
    const errors = {} as errorDataType;
    if (!values.email) {
      errors.email = "Email Address is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validation(formData));
    axios
      .post<response>(`/auth/login`, formData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setResponse(response.data);
        setFormData(initialValue);
        navigate("/chat");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="sign-in-wrapper">
      <div className="signin-form-wrapper">
        <div className="logo">
          <img src={LogoMini} className="logo-icon" alt="logo" />
        </div>
        <div className="auth-form">
          <div className="title">Welcome back</div>
          <div>
            <form
              onSubmit={onFormSubmit}
              className={formErrors ? "formError form" : "form"}
            >
              <TextField
                id="outlined-basic1"
                label="Email address"
                variant="outlined"
                type="email"
                name="email"
                onChange={handleChange}
              />
              {formErrors?.email && <p>{formErrors?.email}</p>}
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                name="password"
                onChange={handleChange}
              />
              {formErrors?.password && <p>{formErrors?.password}</p>}
              <Button type="submit" variant="contained">
                Log in
              </Button>
            </form>
          </div>
          <div className="additional-actions">
            {" "}
            Don't have an account?&nbsp;
            <Link to="/sign-up">Sign up</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
