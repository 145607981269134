import { useCallback, useState } from "react";
import { clsx } from "clsx";
import LogoMini from "../../assets/images/LogoMini.svg";
import useAxios from "axios-hooks";
import Button from "@/components/Button";
import Chatbox from "@/components/Chatbox";

const Chat = () => {
  const [{ loading }, makeRequest] = useAxios({});
  const [sessionId, setSessionId] = useState<null | string>(null);

  const onBtnClick = useCallback(() => {
    makeRequest({
      url: "/chats/session",
      method: "post",
    }).then(({ data }) => {
      setSessionId(data.session.id);
    });
  }, []);

  const onClose = useCallback(() => {
    setSessionId(null);
  }, []);

  return (
    <div className="sign-in-wrapper">
      <div className="signin-form-wrapper">
        <div className="logo">
          <img src={LogoMini} className="logo-icon" alt="logo" />
        </div>
        <div className={clsx({ "auth-form": sessionId === null })}>
          {sessionId === null ? (
            <>
              <div className="title">Start a new chat</div>
              <div>
                <Button
                  loading={loading}
                  onClick={onBtnClick}
                  variant="contained"
                >
                  Start chat
                </Button>
              </div>
            </>
          ) : (
            <Chatbox onClose={onClose} sessionId={sessionId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
